<div class="main-banner mo">
    <div class="d-table">
        <div>
            <!--<img src="../../../assets/img/Capitaltechreviewbanner.jpg" style="width:100%"/>-->
        </div>
        </div>
    </div>
<br /><br />
    <div class="container">
        <img src="../../../assets/img/Comingsoon.png" />
    </div>

