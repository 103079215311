<!--BPO-->
<div class="mo">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb crumb">
            <li class="breadcrumb-item" style="cursor:pointer;">
                <a routerLink="/"><b style="color:#00c8ee">Home</b></a>
            </li>
            <li class="breadcrumb-item bread_active" aria-current="page">

                <b class="enimy">
                    BPO & Managed Services
                </b>

            </li>
        </ol>
    </nav>
</div>
<div class="main-banner">
    <div class="d-table">
        <div>
            <!--<img src="../../../assets/img/CapitaltechITservicesbanner.jpg" />-->
            <img src="../../../assets/img/CapitaltechBposervicesbanner.jpg" style="width:100%"/>
          
        </div>
    </div>
</div>

<br />
<div class="container">
    <div class="section-title">
        <h2> <span>BPO & Managed Services</span></h2>
    </div>
    <div>
        <b>➣</b>
        <p class="con">
            Our Managed Services Division can custom plan a business process and IT managed services
            program to fit the needs and wants of your organization at whatever level you'll require.
        </p>
    </div><br />
    <div><b>➣</b>
        <p class="con">
        These techniques and programs offer assistance to our clients to deliver products and
        services quicker to meet operational challenges and accomplish considerable ROI and extraordinary outcomes.
    </p></div><br />
    <div class="row">
        <div class="col-lg-7">
            <div>
                <b>➣</b>
                <p class="con1">
                    <b>
                        These services range from complete business process outsourcing
                        (BPO)
                    </b> to IT technique, IT Strategy, IT Administration and IT Governance,
                    IT and business process audit and compliance, cybersecurity,
                    application development, testing, big data, cloud, mobility,
                    enterprise and infrastructure services.
                </p>
            </div>
         <div><br />
            <b>➣</b>   <p class="con1">
                We can design Managed Services programs which are straightforward and simple to execute to meet your
             particular prerequisites from a business 
                process and IT point of view.
            </p></div>
        </div>
        <!--<div class="col-lg-1"></div>-->
        <div class="col-lg-5">
            <img src="../../../assets/img/Capitalimage_10.jpg" style="border-radius:16px;" />
        </div>
    </div><br />
    <h2 class="section-title"><span>Our services</span></h2>

    <div class="row">
        <div class="col-lg-5">
            <!--<img src="../../../assets/img/Capitalimage_15.jpg" />-->
            <img src="../../../assets/img/Capitalimage_17.jpg" style="border-radius:16px;" />
        </div>
        <div class="col-lg-1"></div>
        <div class="col-lg-6">
            <div><br />
                <b>➣</b>
                <p class="con1">
                    We are the leading and cutting edge firm in bringing the concept of “total” Managed Services to
                    the industry on an individual or a bundled premise depending upon your special necessities.
                </p>
            </div>
            <div>
                <br /><b>➣</b>
                <p class="con1">
                     We have a worldwide delivery platform reach and international teams of functional and industry experts with offshore
                   conveyance capabilities which provide experienced, prepared,and certified experts and solutions.
                </p>
            </div>
            <p>
                <!--<b>
                IT Strategy,
                IT Governance and Advisory,
            IT Audit,
                Data Management,
                Mobility,
                Cloud,
                Infrastructure,
                RPA,
                Block Chain,
                Artificial Intelligence,
                Big Data Analytics,
                Cyber Security and Data Privacy Process Design and Transformation Services,
                Compliance and Testing Services,
                Business Process Outsourcing (BPO),
                High Velocity ERP SAP/ Oracle Transition and GRC Services
        </b>-->
            </p>
        </div>
    </div><br />
</div>
