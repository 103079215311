<div id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2><span>Our Clients</span></h2>
        </div>
        <div class="row mobileMargin mb-3 " style="margin:0 -22px;">

            <div class="col-lg-2 col-md-2 col-xs-6 col-xs-4">
                <img src="../../../../assets/img/Seal_of_Georgia.svg.png" />
                <br /><p class="state">State Of Georgia</p>

            </div>

            <div class="col-lg-3 col-md-2 col-xs-6 col-xs-4">
                <img src="../../../../assets/img/DC Seal.jpg" class="dcse" />
                <br /><p class="state">State Of Columbia</p>

            </div>

            <div class="col-lg-2 col-md-2 col-xs-6  col-xs-4">

                <img src="../../../../assets/img/IA state seal.jpg" />
                <br /><p class="state">State Of Iowa</p>

            </div>

            <div class="col-lg-3 col-md-2 col-xs-6  col-xs-4">
                <img src="../../../../assets/img/ID sate seal.jpg" class="dcse1" />
                <br /><p class="state1">State Of Idaho</p>

            </div>

            <div class="col-lg-2 col-md-2 col-xs-6  col-xs-4">
                <img src="../../../../assets/img/1200px-Seal_of_Florida.svg.png" />
                <br /><p class="state">State Of Flordia</p>

            </div>


        </div>
    </div>

</div>
<!--<span class="post">Photographer</span>-->
                        
<!--<div class="social">
    <ul>
        <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
        <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
        <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
        <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
    </ul>
</div>-->
