<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h4>We Are Creative</h4>
                    <h1>The Best <span>Digital</span> Experience</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <!--<a (click)="onClick('about')" class="btn btn-primary">Get Started</a>
                    <a (click)="onClick('testing')" class="btn btn-primary">Testing</a>
                    <a (click)="onClick('work')" class="btn btn-primary view-work">View Work</a>-->
                </div>
            </div>
        </div>
    </div>
</div>

<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>About <span>Nutrigene</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>We Are Digital <span>Marketing & Branding</span></h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Creative Design</li>
                        <li><i class="fas fa-check"></i>Retina Ready</li>
                        <li><i class="fas fa-check"></i>Responsive Design</li>
                        <li><i class="fas fa-check"></i>Modern Design</li>
                        <li><i class="fas fa-check"></i>Awesome Design</li>
                        <li><i class="fas fa-check"></i>Digital Marketing & Branding</li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/about.jpg" alt="about">
                    <div class="video-btn">
                        <!--<button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div>


<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>We are creative</h4>
            <h2>Welcome to <span>Nutrigene</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Active Botanical Pharma Ingredients</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectet adipiscing elit consectetur, ipsum dolor sed do eiusmod tempor.</p>
                    <!--<a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>-->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-laptop"></i>
                    </div>
                    <h3>Standardized Botanical Supplements</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectet adipiscing elit consectetur, ipsum dolor sed do eiusmod tempor.</p>
                   <!-- <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>-->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-life-ring"></i>
                    </div>
                    <h3>Novel Standard Extracts</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectet adipiscing elit consectetur, ipsum dolor sed do eiusmod tempor.</p>
                   <!-- <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>-->
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Phyto Chemical Reference Standards</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectet adipiscing elit consectetur, ipsum dolor sed do eiusmod tempor.</p>
                    <!--<a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>-->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Lead Phyto Chemical</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectet adipiscing elit consectetur, ipsum dolor sed do eiusmod tempor.</p>
                    <!--<a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>-->
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>



<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4>Get in Touch</h4>
            <h2>Let's <span>Contact</span> Us</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form #contactForm="ngForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Name</label>
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Name is required.</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Email</label>
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Subject</label>
                                    <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject">
                                    <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Phone subject is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Phone Number</label>
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Message</label>
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <!--<button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">Send Message</button>-->
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="fas fa-map-marker-alt"></i> <span>Location:</span>Exercisplan 4, 111 49 Stockholm, Sweden</li>
                                <li><i class="far fa-envelope"></i> <a href="mailto:hello@xavro.com"><span>Email:</span>hello@xavro.com</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:1244122445515"><span>Call:</span>(+124)412-2445515</a></li>
                                <li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



