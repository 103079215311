<!--<div class="home-slides" style="margin-top:10%;">
    <owl-carousel-o [options]="homeSlides">
        <ng-template carouselSlide>
            <div class="main-banner item-bg-two">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg-one">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg-three">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>-->
<!--<div class="main-banner">
    <div class="d-table">
        <div>
            <img src="../../../assets/img/Banner1_1.png"class="ferin-img"  />
            <div class="center">
                <h1  class="ferin">Withaferin A</h1>
                <h3 class="ferin_1">
                    For including cardioprotective, anti-inflammatory, <br />immuno-modulatory,
                    anti-angiogenesis,<br /> [2] anti-metastasis and anti-carcinogenic properties.
                </h3>
            </div>
        </div>
    </div>
</div>

<div class="container" style="margin-top:20px">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/"><b>Home</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page">
                <b class="enimy">
                    Active botanical pharma ingredients
                </b>
            </li>
        </ol>
    </nav>


    <div class="row mobileMargin mb-3" style="margin:0 -22px;">
        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6" *ngFor="let item of dataResult">
            <div class="card card_border single" (click)="navigatingToSelectedProduct(item)"
                 style="cursor:pointer;margin: 4%;width: -webkit-fill-available;box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;border:1px solid green;border-radius:0 55px 0 55px;">

                <a class="Num-btn"> {{item.Catalog_no}}</a>
                <div class="card-bodyy">
                    <div class="demoWrapper">
                        <div *ngIf="item.ProductImage != null ||item.ProductImage != '' ">
                            <img src="{{HomeUrl+item.ProductImage}}" class="card_image" alt="Nutrigene" *ngIf="item.ProductImage">
                        </div>
                        <div *ngIf=" item.ProductImage == null || item.ProductImage == '' ">
                            <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="Nutrigene">
                        </div>
                    </div>
                    <hr class="hrl" />
                    <h5 class="card-titlle" style="text-align:center">{{item.ProductName}}</h5>
                    <br />
                    <div class="row">
                        <div class="col-lg-5 col-sm-5 col-xs-6 Label_left">
                            <b class="casn" *ngIf="item.CasNumber"> Cas Number  </b>
                            <b class="grades" *ngIf="item.OfferingGrades">Grades</b>
                            <b class="spec" *ngIf="item.Specification">Specification</b>
                            <b class="botanical" *ngIf="item.Botanicalsource"> Plant Source </b>
                            <b class="trade" *ngIf="item.TradeMark"> TradeMark </b>
                        </div>
                        <div class="col-lg-7 col-sm-7 col-xs-6 Label_right">
                            <p class="casn_1">{{item.CasNumber}}</p>
                            <p class="grades_1">{{item.OfferingGrades}}</p>
                            <p class="spec_1">{{item.Specification}}</p>
                            <p class="botanical_1">{{item.Botanicalsource}}</p>
                            <p class="trade_1">{{item.TradeMark}}</p>
                        </div>
                    </div>
                </div>
                <a routerLink="/LeadPhytoChemical" title="Share" class="link-btn"><i class="fa-solid fa-share-nodes"></i></a>
            </div>
        </div>
    </div>


</div>-->
<div class="container">

</div>



