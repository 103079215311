<div class="mo">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb crumb">
            <li class="breadcrumb-item" style="cursor:pointer;">
                <a routerLink="/"><b style="color:#00c8ee">Home</b></a>
            </li>
            <li class="breadcrumb-item bread_active" aria-current="page">

                <b class="enimy">
                    Careers
                </b>

            </li>
        </ol>
    </nav>
</div>

<div class="main-banner">
    <div class="d-table">

        <img src="../../../assets/img/Capitalimagecareersbanner.jpg" style="width:100%" />
       
    </div>
</div>

<br />
<div class="container" style="margin-top:20px;margin-bottom: -40px;background-color:white ">

    <div class="row">
        <div class="col-lg-9 lap" style="  border-radius: 7px;
    border-color: #0f76a1;">
            <i class="fa fa-search"></i>&nbsp;
            <p-autoComplete placeholder=" Search with technology, company....." [(ngModel)]="selectedcompanys" [suggestions]="searchcomp" (completeMethod)="filterTests()"
                            field="CompanyName" [dropdown]="false" class="lab" (onClear)="clearItem($event)" style="  border-radius: 7px;
    border-color: #0f76a1;"  >


            </p-autoComplete>
        </div>
        <div class="col-lg-3" style="text-align:right;    width: 199px;margin-left: 75px;">
            <span class="input-group-text"><b>Total Jobs: {{searchcompany.length}}</b></span>

        </div>
    </div>


</div><br /><br />
<div class="container">
  
  
    <div class="row">        
            <div class="w3-card col-lg-4 col-sm-12" *ngFor="let item of searchcompany" style="border-radius:9px;">
                <header class="w3-container">
                    <div style="text-align:center;    color: black; font-weight: 600;">{{item.JobTitle}}</div>                 
                </header>
                <hr  class="new4">
                <div class="w3-container"  (click)="navigatingTojobdetails(item)">
                    <p style="color:#00c8ee">{{item.CompanyName}}</p>
                   

                    <p><i class="fas fa-map-marker-alt" style="padding:5px;margin-left:-5px;"></i>{{item.CityName}}/{{item.StateName}}</p>
                    <p>
                      
                        <img style="    width: 20px;" src="../../../assets/img/download.png"/> {{item.Experience}}
                    </p>
                </div>

                <footer class="w3-container">
                    <div class="row">
                        <!--<div class="col-lg-6"><h5>Posted on: {{item.CreatedDate  | date:'M/d/yy'}}</h5></div>-->
                        <div class="col-lg-12 but_0">
                            <button type="button"class="readmorebutton_0">

                         <p class="read"(click)="navigatingTojobdetails(item)" style="color:black">Read more</p>
                            </button>
                        </div>
                    </div>
                </footer>
            </div>        
    </div>
    <br />

</div>
<style>
    @media only screen and (max-width:600px){

        input {
            width: 275px;
            height: 35px;
            /* border-block-color: revert; */
            border-color: #e2e9e9;
        }
    }
    .read{
        padding-top:10px;

    }
    p:hover{
        color:deepskyblue;
    }
</style>

