<div class="main-banner mo_0">
    <div class="d-table">

        <div class="d-table-cell mart">
            <!--<img src="../../../assets/img/homebanner.jpeg" />-->

            <img src="../../../assets/img/Capitaltechbanner.jpg" />
            <div class="row wor ">
                <div class="col-lg-2"></div>
                <div class="col-lg-5 col6">
                    <h5 class="js-nametag tag1" style="text-align:center;"><b class="cap"><span style="color:#215e74;">CAPITAL TECH SOLUTIONS </span>helped jobseekers to get jobs in US.</b></h5>
                    <h5 class="js-nametag tag" style="text-align:center;">
                        <b class="cap">We work across numerous industries, job specialities & locations.</b><br /><br />
                        <b class="cap1">send your resume now to<span style="color:#215e74;"> hr@capitaltechsolutions.com</span></b>
                    </h5>
                </div>
                <div class="col-lg-1 col2"></div>
                <div class="col-lg-4 marto">
                    <p class="current">Current Openings</p>
                    <div class="card1 pad card_1">

                        <div class="d-flex justify-content-between align-items-center breaking-news ">

                            <marquee behavior="scroll" scrolldelay="120" direction="Up" onmouseover="this.stop();" onmouseout="this.start();" class="hei">
                                <span *ngFor="let item of Careers; let i=index">
                                    <a (click)="navigatingTojobdetails(item)">
                                        <div style="width:100%"><span style="color:#11a2d9">{{item.JobTitle}}</span> - {{item.CityName}}, {{item.StateName}}</div>

                                    </a><hr />

                                </span>

                            </marquee>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<br />
<br />
<br />
<!--<app-testing></app-testing>-->

<app-about></app-about>

<app-strategy></app-strategy>

<app-welcome></app-welcome>

<app-who-we-are></app-who-we-are>

<!--<app-cta></app-cta>

<app-skill></app-skill>

<app-funfacts></app-funfacts>-->

<app-how-we-work></app-how-we-work>

<app-services-two></app-services-two>

<app-team></app-team>

<!--<app-why-we-different></app-why-we-different>

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>

<app-feedback></app-feedback>

<app-blog></app-blog>

<app-partner></app-partner>

<app-subscribe></app-subscribe>

<app-contact></app-contact>-->
