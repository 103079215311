import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { FormGroup, FormsModule } from '@angular/forms'

import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-job-apply',
  templateUrl: './job-apply.component.html',
  styleUrls: ['./job-apply.component.scss']
})
export class JobApplyComponent implements OnInit {
  
    EnquiryAry: any = [];
    formGroup: any;
    admin: any;
    TemplateId: any;
    MessageText: any;
    Attachment: any;
    contactForm: FormsModule;
    SubCatId: any;
    jobdetails: any;
    JobCode: any;
    JobTitle: any;
    JobDescription: any;
    CompanyName: any;
    StateName: any;
    CityName: any;
    JobTypeName: any;
    JobDuration: any;
    Experience: any;
    CreatedDate: any;

    whatsAppProductURL: string;
    HomeURL: any;
    CatId: any;
    catName: string;
    JobID: any;
    @Output() someEvent = new EventEmitter<string>();
    activeroute: any;

    spinner: boolean = false;
    imgBase64: any = '';

    HomeUrl: any;
    Tests: any[];
    imagefile: string | ArrayBuffer;
    image: any;

    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient, public fb: FormsModule, public route: ActivatedRoute) {
        debugger
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeURL = data.Webservice;
            debugger
            this.JobCode = this.route.snapshot.paramMap.get('JobApply').replace(/\_/g, " ").trim();
            this.generalService.GetProducts().subscribe(res => {
                if (res.length == 0) {//if category id is null
                    this.GetjobdeatilsbasedonJobCode()
                }
                else {
                    this.JobCode = res[0].JobCode;
                    this.GetjobdeatilsbasedonJobCode()

                }
            })
        });
    }
    ngOnInit(): void {
      
    }
    GetjobdeatilsbasedonJobCode() {
        debugger
        var UploadFile = new FormData()
        UploadFile.append("Param", this.JobCode)
        var url = "/api/Rank/Get_Capitaltech_Careers"
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.jobdetails = data;
            this.JobID = (this.jobdetails[0].JobID);
            this.JobCode = (this.jobdetails[0].JobCode);
            this.JobTitle = (this.jobdetails[0].JobTitle);
            this.JobDescription = (this.jobdetails[0].JobDescription);
            this.CompanyName = (this.jobdetails[0].CompanyName);
            this.StateName = (this.jobdetails[0].StateName);
            this.CityName = (this.jobdetails[0].CityName);
            this.JobTypeName = (this.jobdetails[0].JobTypeName);
            this.JobDuration = (this.jobdetails[0].JobDuration);
            this.Experience = (this.jobdetails[0].Experience);
            this.CreatedDate = (this.jobdetails[0].CreatedDate);


        }, err => {
            
        });
    }
    back() {
        this.Experience = '';
      
    }
 

    onSubmit(form: any) {
        debugger;

        this.EnquiryAry = []; this.EnquiryAry.push({ JobTitle: this.jobdetails[0].JobTitle, Name: form.name, Number: form.number, Email: form.email, Experience: form.Experience, Message: form.message, Attachment: this.imagefile })
        var UploadFile = new FormData();
        UploadFile.append("Ticket1", JSON.stringify(this.EnquiryAry));
        var url = "/api/Rank/EnquiryMailToAdmin"
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger;

            //
            Swal.fire(
                'SUCCESS',
                'Your Job Enquiry has been submitted successfully',
                'success'
               

            )
            // this.sendsms();
            
        });

    }
   
    detectFiles(input) {
        debugger

        const reader = new FileReader();
        if (input.files.length) {
            debugger
            const file = input.files[0];
            reader.onload = () => {
                debugger
                this.imagefile = reader.result;
                this.image = this.imagefile;
            }
            reader.readAsDataURL(file);
            var selectedFile = file;
            var idxDot = selectedFile.name.lastIndexOf(".") + 1;
            var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "doc" || extFile == "pdf" ) {
                debugger
                var UploadFile = new FormData();
                UploadFile.append("PostedFile", file);
                var url = 'api/Rank/UploadEmergencyTypeImage';
                this.generalService.PostData(url, UploadFile).then((data: any) => {
                    if (data != null) {
                        debugger
                        this.imagefile = data;
                        this.image = data;
                    }
                });
            }
            else {
                alert("Only doc/jpg/jpeg, png files are allowed!");
            }

        }
    }
    ngAfterViewInit() {
        document.getElementById('preloader').classList.add('hide');
    }
   


 
}
