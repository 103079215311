<div id="about" class="about-area ptb-100 con">
    <div class="container ">
        <div class="section-title">
            <div class="row">
                <div class="col-lg-6">
                    <h2>Why <span>Us</span></h2>
                    <p style="border-radius:2%">
                        Capital Tech Solutions is IT Consulting and staff augmentation company headquartered in Washington DC. We are dedicated to help businesses maximize their profits by managing their information more efficiently. We provide enterprise software, business intelligence and automation solutions for every element of your business, from sales and customer service through supply management and logistics.
                    </p>
                </div>
                <div class="col-lg-6">
                    <img src="../../../../assets/img/Capitalimage_1.jpg"style="border-radius:18px;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;" />
                    <!--<img src="../../../../assets/img/why us.jpg" style="border-radius:6.5%;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;" />-->
                </div>
            </div>
        </div>
        <br />
        <div class="row " style="text-align:center">
            <div class="col-lg-6 col-md-12">
                <img src="../../../../assets/img/Capitalimage_2.jpg"style="border-radius:18px;;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;" />
                <!--<img src="../../../../assets/img/what can.jpg"style="border-radius:6.5%;height: 77%; width: 77%;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;" />-->
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2 class="do"><span>What We Do</span></h2>
                        <p style="text-align:initial">
                            Recruitment is a core function of the Human Resource department. It is a process that involves everything from identifying, attracting, screening, shortlisting, interviewing, selecting, hiring, and onboarding employees. The recruitment teams can be large or small depending on the size of an organization. However, in smaller organizations, recruitment is typically the responsibility of a recruiting manager
                        </p>
                        <b>Whatever your IT staffing need, we are built to help. </b>
                    </div>

                </div>
            </div>
        </div><br />
        <div id="services" class="services-area pt-100 pb-70">
            <div class="container">
                <div class="section-title">
                    <h2> <span>Our Approach</span></h2>
                    <!--<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-services">
                            <!--<img src="../../../../assets/img/con_0.jpg" style="width:349px;height:160px;" />-->
                            <img src="../../../../assets/img/Capitalimage_3.jpg" style="border-radius:18px 18px 0 0  !important;" />

                            <div class="services-content">
                                <h3>Dedicated Teams</h3>
                                <p>CapitalTechSolutions builds a dedicated team of recruiters for each client. Each team knows what makes their client unique and uses that knowledge to source, screen, and place the best candidates each and every time.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-services">
                            <img src="../../../../assets/img/Capitalimage_4.jpg" style="border-radius:18px 18px 0 0  !important;" />
                            <!--<img src="../../../../assets/img/cons.jpg" style="width:349px;height:160px;" />-->
                            <div class="services-content">
                                <h3>Excellence and Speed</h3>
                                <p>
                                    Our teams understand the importance of speed and excellence when it comes to staffing within the programs. That is why they are trained to leverage the latest technology to aid in all of their recruiting efforts.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-services">
                            <img src="../../../../assets/img/Capitalimage_5.jpg" style="border-radius:18px 18px 0 0  !important;"/>
                            <!--<img src="../../../../assets/img/cons_1.jpg" style="width: 349px; height: 160px;" />-->


                            <div class="services-content">
                                <h3>Optimization</h3>
                                 <p> As a technology company at heart, Capital Tech is obsessed with data.
                                We use it to measure every aspect of our staffing efforts and are constantly making
                                adjustments to optimize performance.</p>

                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <!--<div class="col-lg-6 col-md-12">
        <div class="about-video">
            <img src="assets/img/about.jpg" alt="about">
            <div class="video-btn">
                <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button>
            </div>
        </div>
    </div>-->

    </div>

<div class="video-popup">
    <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div>
