import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';


import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


import { Router } from '@angular/router';

@Component({
    selector: 'app-standardized-botanical-supplements',
    templateUrl: './standardized-botanical-supplements.component.html',
    styleUrls: ['./standardized-botanical-supplements.component.scss']
})
export class StandardizedBotanicalSupplementsComponent implements OnInit {
    HomeUrl: any;
    add: boolean = true;
    dataResult: any = [];
    arr: any[];
    dataResult1: any = [];
    Products: any = [];
    ProductsList: any = [];
    SubCatID: any;
    CatID: 3;
    dataResult11: any;
    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient) {
        debugger
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }

    ngOnInit(): void {
        this.GetProducts();
        this.getCat();
    }
    GetProducts() {
        debugger
        var url = "api/Nutrigene/Get_subCategories_basedonCatid3";
        this.generalService.GetData(url).then(data => {
            this.dataResult = data;
            this.Getsubproducts();
            //console.log(data);
        }, err => {
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    Getsubproducts() {
        debugger
        this.arr = []
        this.arr.push({ CatId: '3' });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        var url = "api/Nutrigene/Get_NU_Products";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            if (data.listProducts.length > 0) {
                this.Products = data.listProducts;
                this.ProductsList = [];
                for (var x = 0; x < this.dataResult.length; x++) {
                    var list = this.Products.filter(re => re.SubCatId == this.dataResult[x].SubCatId);
                    this.dataResult[x].products = list;
                }
            }
        }, err => {
        })
    }

    getCat() {
        debugger
        var url = "api/Nutrigene/Get_NU_Products1";
        this.generalService.GetData(url).then(data => {
            debugger
            this.dataResult11 = data;
            this.GetProducts();
        }, err => {
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    getSubCat(SubCatId: any) {
        this.SubCatID = SubCatId;
    }

 

    rating(el: HTMLElement) {

        el.scrollIntoView();
    }
}
