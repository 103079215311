
<div class="moo1 deta" >
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb crumb">
            <li class="breadcrumb-item">
            <a routerLink="/"><b style="color:#00c8ee"class="ho">Home</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page">
                <a routerLink="/Careers">  <b class="enimy">
                    Career
                </b>/</a><b>{{jobdetails[0].JobTitle}}</b>
            </li>
        </ol>
    </nav>
</div>

<div class="moo" style="background-color:#ebeef1;">
    <br /><br />


    <h5 class="about"><b>About the job  Details</b> </h5>
    <img class="butt11" src="../../../assets/img/share.png" (click)="sendWhatsapp(jobdetails[0])" /><br />
    <div>
        <button type="button" class="btn btn-info butt">
            <p class="read" (click)="navigatingTojobdetails(jobdetails[0].JobCode)">Apply</p>
        </button>
    </div>
    <br /><br />
    <div class="container" style="background-color:white;  margin-top: -20px;">

        <div class="row">

            <div class="col-lg-4 ">
                <div class="decCard1">
                    <img src="../../../assets/img/Capitaltech_Logo.png"style="width: 50%;" />
                    <!--<img src="../../../assets/img/CapitaltechNewlogo.png" style="    width: 40%;" />-->

                </div>
            </div>


            <div class="col-lg-8 java ">
                <div class="decCard dec_0">
                    <h2 class="h22" style="color:#00c8ee;">{{jobdetails[0].JobTitle}}</h2><br />
                    <p class="com_0">{{jobdetails[0].CompanyName}}</p>
                    <p class="loc_0"><i class="fas fa-map-marker-alt"></i>{{jobdetails[0].CityName}}/{{jobdetails[0].StateName}}</p>
                    <p class="about_0"><img style="width: 18px;" src="../../../assets/img/download.png" />   {{jobdetails[0].Experience}}</p>
                </div>

            </div>


        </div>

        <div class="container" style="background-color:#b8bdc1;">

            <div class="row">
                <div class="col-lg-4">JobID : {{jobdetails[0].JobCode}}</div>
                <div class="col-lg-4">JobType : {{jobdetails[0].JobTypeName}}</div>
                <div class="col-lg-4"><i class="fa-solid fa-laptop"></i> &nbsp;{{jobdetails[0].WorklocationName}}</div>

            </div>
        </div>
        <br /><br />

              <div class="container" style="margin-bottom:30px;">
                  <h5 style="color:black;font-size: 30px;margin-left: 9px;">  Job Description </h5><br />

                  <div style="color:black;margin-left: 18px;" [innerHtml]="jobdetails[0].JobDescription"></div>
              </div><br />
    </div>
    <br /><br />
</div>
<br /><br /><br />


<style>
    @media only screen and (max-width:600px){
        .moo1 {
            margin-top: 36%;
            width: 98%;
        }
        .decCard1{
            margin-top: 38px;
            margin-bottom: 30px;
        }
        .deta {
            background-color: white;
            font-size: 15px;
            margin-bottom: -69px;
        }

        .butt11 {
            height: 29px;
            margin-left: 243px;
            margin-top: -28px;
        }

        .butt {
            margin-left: 183px;
            margin-top:4px;
        }
        .ho {
            color: #00c8ee;
            margin-left: -36px;        }
    }
</style>
