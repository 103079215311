
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb crumb">
            <li class="breadcrumb-item" style="cursor:pointer;">
                <a  (click)="onClick()"><b style="color:#00c8ee">Home</b></a>
            </li>
            <li class="breadcrumb-item bread_active" aria-current="page">

                <b class="enimy">
                    About Us
                </b>

            </li>
        </ol>
    </nav>




    <div class="main-banner ">
       
        <div>
            <!--<img src="../../../assets/img/CapitaltechContactusbanner.jpg" style="width:100%" />-->
            <img src="../../../assets/img/Aboutusbanner.jpg" style="width:100%;" />
        </div>
        </div>
   
    
<br />

    <div class="container" style="margin-top:0%">

        <div class="col-lg-10">
            <div class="section-title">
                <h2> <span>About Capital Tech Solutions</span></h2>
            </div>
        </div><br /><br />
        <div class="row">

            <div class="col-lg-5">
                <div class="con">
                    <img src="../../../assets/img/CapitaltechLatestLogo.png"class="cdc" alt="capitaltech" />
                    <!---<p>
        Our philosophy is to make and energize valuable & productive employee and client
        relationships where we all exceed expectations as a result of making positive impact
        in each other’s life.
    </p>--->
                </div>
            </div>
            <div class="col-lg-7">
                Our philosophy is to make and energize valuable & productive employee and client
                relationships where we all exceed expectations as a result of making positive impact
                in each other’s life.
                <!---<img src="../../../assets/img/CapitaltechNewlogo.png" class="cdc" alt="capitaltech" />--->
            </div>
        </div>
        <br /><br />
        <div class="container">
            <h1 class="section-title"><span>Vision</span> </h1>

            <div class="row">
                <div class="col-lg-7 vision">
                    <p>
                        Our Vision To be a driving force, in fulfilling information technology consulting
                        solutions and services and contributing to our partners growth.
                    </p>
                    <!---<img src="../../../assets/img/Vissionimage.jpg" style="border-radius:16px;" />--->
                </div>
                <div class="col-lg-1"></div>
                <div class="col-lg-4">
                    <img src="../../../assets/img/Vissionimage.jpg" style="border-radius:16px;" />
                    <!---<p>
                        Our Vision To be a driving force, in fulfilling information technology consulting
                        solutions and services and contributing to our partners growth.
                    </p>--->
                </div>
            </div>
        </div>
        <br /><br />
        <div class="container">

            <h1 class="section-title"><span>Mission</span> </h1>

            <div class="row">
                <div class="col-lg-4 mision_1">
                    <img src="../../../assets/img/Missionimage.jpg" style="border-radius:16px;" />
                    <!-- <p style="text-align:initial">
                        Our Mission To be a process driven organization, accomplishing subject matter
                        ability in each of our domains. To construct a development culture for personal
                        ability, aligned to business outcomes
                    </p>-->
                </div>
                <div class="col-lg-1"></div>
                <div class="col-lg-7">
                    <p style="text-align:initial">
                        Our Mission To be a process driven organization, accomplishing subject matter
                        ability in each of our domains. To construct a development culture for personal
                        ability, aligned to business outcomes
                        <!---<img src="../../../assets/img/Missionimage.jpg" style="border-radius:16px;" />--->
                </div>
            </div>
        </div>



    </div>

    <br /><br />
<style>
    @media only screen and (max-width:600px) {
        .mo {
            margin-top: 23%;
        }
    }
</style>
