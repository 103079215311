import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';

import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';

@Component({
  selector: 'app-bakuchiol',
  templateUrl: './bakuchiol.component.html',
  styleUrls: ['./bakuchiol.component.scss']
})
export class BakuchiolComponent implements OnInit {
    Careers: any ;
    arr: any[];
    selectedcompanys: any;
    selectedlocation: any;
    selectedexperince: any;
    searchcompany: any = [];
    searchcomp: any;
    JobCode: any;
    searchlist: any = [];
    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient, public activeroute: ActivatedRoute) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.JobCode = this.activeroute.snapshot.paramMap.get('Name').replace(/\_/g, " ").trim();
        });
    }

    ngOnInit(): void {
        this.GetCareerPosts();
    }
    filterTests() {
        debugger
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        let filtered: any[] = [];
        var UploadFile = new FormData()
        UploadFile.append("Param1", this.selectedcompanys)
     
        var url = "api/Rank/search_CaptitalTech"
        this.generalService.PostData(url, UploadFile).then(data => {
            this.searchcompany = data
        }, err => {
            //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
    }
    clearItem(autocomplete: any) {
        this.searchlist = [];
        debugger
        autocomplete.value = '';
        this.filterTests();
        //window.location.reload();
       this.searchlist = this.searchcompany;
    }
    filter(event) {
        //let filtered: any[] = [];
        //let query = event.query;


      
            debugger
        let query = event.detail.value;
       
        this.searchlist = [];
       
            this.searchlist = this.searchcompany.filter((KR) => {
                return (
                    KR.CompanyName.toLowerCase().indexOf(query.toLowerCase()) > -1);
               
            })
       


        //this.searchlist = [];
        //for (let i = 0; i < this.searchcompany.length; i++) {
        //    let cat = this.searchcompany[i];
        //    if (cat.CompanyName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        //        filtered.push(cat);
        //    }
        //}
        //this.searchlist = filtered;

    }
    search(ev) {
        debugger
        if (ev) {
            this.searchcompany = this.searchlist.filter(a => a.CompanyName == ev.CompanyName);
        }
        else {
            this.searchcompany = this.searchlist
       
        }
    }

    keyUp(ev) {
        debugger
        this.searchcompany = this.searchlist.filter((item) => {
            return item.CompanyName.toLowerCase().startsWith(ev.target.value.toLowerCase())
           
       
        });
    }
    GetCareerPosts() {
        debugger
        this.arr = []
        this.arr.push({
            JobID:1  
        });
        var UploadFile = new FormData();
        UploadFile.append("Xml", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/Rank/CapitalTech_Careers_CRUD";
        this.generalService.PostData(url, UploadFile).then(data => {
        debugger
            this.Careers = data;
            this.searchcompany = data
        //console.log(data);
        }, err => {
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }
    navigatingTojobdetails(data) {
        debugger
        var Jobcode = ""; var prodarr = []
        prodarr.push(data)
        var str = data.JobCode.split(" ")//spliting the category name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            Jobcode += "_" + str[i];
        }
        this.generalService.setProducts(prodarr)
        this.router.navigate(['/JobCode/' + Jobcode]);
    }


    
}
