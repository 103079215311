<!--<div class="strategy-area">
    <div class="container">
        <div class="row">
            <h3 style="padding:3%">WORLD'S ONLY CRYSTAL CLEAR BAKUCHIOL COLORLESS. ODORLESS. FREEDOM TO FORMULATE IN ANY CONCENTRATION & MEDIA WITHOUT CHANGING PRODUCT COLOR & CHARACTER.</h3>
            <div class="col-lg-5 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/BakuchiolH1.png" alt="image" style="border-radius:18px;">
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h2 style="color: #0baddf">Our Flagship Product BAKUCHIOL</h2>
                        <h4>THE PUREST CLEANEST MOST TRANSPARENT BAKUCHIOL IN THE WORLD.</h4> <br />                      
                        <p>Proprietary Green Process with Liquid Gold Clarity & Near-Zero Furocoumarins (Psoralen & Angelicin).</p> <br />
                        <p>Bakuchiol is a meroterpene (a chemical compound having a partial terpenoid structure) in the class terpenophenol, from Psoralea corylifolia seeds as botanical source. </p> <br />
                        <p>Bakuchiol is a useful compound against oral pathogens and has a great potential for use in food additives and mouthwashes for preventing and treating dental caries.
                         The cell growth of S. mutans was inhibited in a bakuchiol concentration
    dependent--> <!--manner and the growth of S. mutans was completely prevented with 20 μg of bakuchiol/mL.</p> <br />-->
                        <!--<p>Bakuchiol is an effective vitiligo treatment and also treats related skin diseases and conditions. It shrinks white spots on skin and reduces pigmentation.
                         Bakuchiol is the first natural alternative to Retinol without having any of the negatives associated with retinol. It is photochemically and hydrolytically stable and can be used during the day.</p>--> <br /><br />
                        <!--<div style="text-align:right;margin-right:10%"><button type="button" class="btn btn-outline-info"><a routerLink="/Bakuchiol"> Read more about Bakuchiol</a></button></div>
                    </div>-->
                    <!--<ul class="features-list">
                        <li><i class="fa fa-check"></i>Creative Design</li>
                        <li><i class="fa fa-check"></i>Retina Ready</li>
                        <li><i class="fa fa-check"></i>Responsive Design</li>
                        <li><i class="fa fa-check"></i>Modern Design</li>
                        <li><i class="fa fa-check"></i>Awesome Design</li>
                        <li><i class="fa fa-check"></i>Digital Marketing & Branding</li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
                <!--</div>
            </div>
        </div>-->

        <!--<div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>Highly Creative Solutions</h4>
                        <h2>Our <span>Mission</span></h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fa fa-check"></i>Creative Design</li>
                        <li><i class="fa fa-check"></i>Retina Ready</li>
                        <li><i class="fa fa-check"></i>Responsive Design</li>
                        <li><i class="fa fa-check"></i>Modern Design</li>
                        <li><i class="fa fa-check"></i>Awesome Design</li>
                        <li><i class="fa fa-check"></i>Digital Marketing & Branding</li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/about-strategy.jpg" alt="image">
                </div>
            </div>
        </div>-->
    <!--</div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>-->
