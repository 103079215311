import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeOneComponent } from './components/home-one/home-one.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-five/home-five.component';
import { HomeSixComponent } from './components/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/home-thirteen/home-thirteen.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { LoginComponent } from './login/login.component';
//import { PagelayoutComponent } from './pagelayout/pagelayout.component';
import { HomeComponent } from './pagelayout/home/home.component';
import { AboutUsComponent } from './pagelayout/about-us/about-us.component';
import { PageHeaderComponent } from './pagelayout/page-header/page-header.component';
import { PageFooterComponent } from './pagelayout/page-footer/page-footer.component';
//import { ActiveBotanicalPharmaIngredientsComponent } from './pagelayout/active-botanical-pharma-ingredients/active-botanical-pharma-ingredients.component';
//import { StandardizedBotanicalSupplementsComponent } from './pagelayout/standardized-botanical-supplements/standardized-botanical-supplements.component';
//import { NovelStandardExtractsComponent } from './pagelayout/novel-standard-extracts/novel-standard-extracts.component';
//import { PhytoChemicalReferenceStandardsComponent } from './pagelayout/phyto-chemical-reference-standards/phyto-chemical-reference-standards.component';
//import { LeadPhotoChemicalComponent } from './pagelayout/lead-photo-chemical/lead-photo-chemical.component';
import { ContactUsComponent } from './pagelayout/contact-us/contact-us.component';
//import { EnquiryComponent } from './pagelayout/enquiry/enquiry.component';
//import { NewsAndEventsComponent } from './pagelayout/news-and-events/news-and-events.component';
import { GalleryComponent } from './pagelayout/gallery/gallery.component';
import { DownloadBrochersComponent } from './pagelayout/download-brochers/download-brochers.component';
import { ProductDetailsComponent } from './pagelayout/product-details/product-details.component';
import { BakuchiolComponent } from './pagelayout/bakuchiol/bakuchiol.component';
import { ServicesComponent } from './pagelayout/services/services.component';
import { ReviewsComponent } from './pagelayout/reviews/Reviews.Component';
import { EnquiryComponent } from './pagelayout/enquiry/enquiry.component';

import { JobDetailsComponent } from './pagelayout/job-details/job-details.component';
import { JobApplyComponent } from './pagelayout/job-apply/job-apply.component'
const routes: Routes = [
   
   
    { path: '', component: HomeOneComponent }, 
    { path: 'login', component: LoginComponent },
    { path: 'Home', component: HomeComponent },
    { path: 'about-us', component: AboutUsComponent },
    { path: 'PageHeader', component: PageHeaderComponent },
    { path: 'PageFooter', component: PageFooterComponent },
    { path: 'DataAnalytics', component: ServicesComponent },
    {path: 'reviews', component: ReviewsComponent,},

    { path: 'GRCSolutions', component: ProductDetailsComponent },
    { path: 'ITFinancial', component: EnquiryComponent },
   
    { path: 'JobCode/:JobDetails', component: JobDetailsComponent },
  
    //{ path: 'NewsAndEvents', component: NewsAndEventsComponent },
    { path: 'BPO&Managed', component: GalleryComponent },
    { path: 'ERPImplementation', component: DownloadBrochersComponent },
    { path: 'Careers', component: BakuchiolComponent },

    { path: 'JobId/:JobApply', component: JobApplyComponent },
    


    { path: 'ContactUs', component: ContactUsComponent },
    { path: 'home-two', component: HomeTwoComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'home-four', component: HomeFourComponent},
    {path: 'home-five', component: HomeFiveComponent},
    {path: 'home-six', component: HomeSixComponent},
    {path: 'home-seven', component: HomeSevenComponent},
    {path: 'home-eight', component: HomeEightComponent},
    {path: 'home-nine', component: HomeNineComponent},
    {path: 'home-ten', component: HomeTenComponent},
    {path: 'home-eleven', component: HomeElevenComponent},
    {path: 'home-twelve', component: HomeTwelveComponent},
    {path: 'home-thirteen', component: HomeThirteenComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    // Here add new pages component

    { path: '**', component: HomeOneComponent } // This line will remain down from the whole pages component list
];

//@NgModule({
//    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
//    exports: [RouterModule],
 
//})
//export class AppRoutingModule { }

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            //preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            //relativeLinkResolution: 'legacy',
            useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
}) 
export class AppRoutingModule { }
