<div class="main-banner">
    <div class="d-table">
        <div>
            <img src="../../../assets/img/Banner3_3.png" class="torine-img"/>
            <div class="center">                
                <h1 class="torine">Pellitorine </h1>
                <!--<h3 style="font-size:18px">
                    A potential anti-cancer lead compound against HL60
                    <br />and MCF-7
                    cell lines
                </h3>-->
            </div>
        </div>
    </div>
</div>

<div class="container" style="margin-top:20px">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/"><b>Home</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><b class="enimy">Standardized Botanical Supplements</b></li>
        </ol>
    </nav>
    <div ></div>
    <div class="row mobileMargin mb-3 " >
        <div class="col-lg-3 col-md-2 card col-xs-6">
            <div (click)="getCat()" id="click" style="cursor:pointer;">
                <img src="assets/img/BakuchiolH1.png" class="card_image col-xs-6" alt="Nutrigene" style="cursor:pointer;" />
                <h5 class="card-titlle title"><b>All Products</b></h5>
            </div>
        </div>
        <div class="col-lg-3 col-md-2 card col-xs-6" *ngFor="let item of dataResult" (click)="rating(ratingsec)">
            <div (click)="getSubCat(item.SubCatId)" id="click" style="cursor:pointer;">
                <img src="{{HomeUrl+item.SubcatImage}}" class="card_image col-xs-6" alt="Nutrigene" style="cursor:pointer;">
                <h5 class="card-titlle title"><b>{{item.SubCategoryName}}</b></h5>
            </div>
        </div>
    </div>
</div>
<!--<hr style="width:80%;margin-left:8%" />-->
<div class="category" #ratingsec >
    <div class="container" style="margin-top:20px">

        <div class="row mobileMargin mb-3" >
            <div *ngFor="let item of dataResult">
                <div *ngIf="item.SubCatId==SubCatID">
                    <hr />
                    <h4 style="font-family: open sans!important;font-size: 36px;"><b><span style=" color:#14818f">{{item.SubCategoryName}}</span></b></h4><br />
                    <p style="color:#4b4c50;font-size:13px">{{item.Description}}</p><br />
                    <table id="customers" style="overflow-wrap: anywhere;">
                        <tr style="font-size:18px">
                            <th>Product Name</th>
                            <th>Active Supplement</th>
                            <th>CAS Number</th>
                            <th>Grades</th>
                            <th>Botanical Source</th>
                        </tr>
                        <tr *ngFor="let pro of item.products">
                            <td> {{pro.ProductName}}</td>
                            <td> {{pro.Name_ofthe_Active_Supplement}}</td>
                            <td>{{pro.CasNumber}}</td>
                            <td>{{pro.OfferingGrades}}</td>
                            <td>{{pro.Botanicalsource}}</td>
                        </tr>
                    </table>
                    <br />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="category" #ratingsec>
    <div class="container" style="margin-top:20px">
        <div class="row mobileMargin mb-3">
            <div *ngFor="let item of dataResult11">
                <div *ngIf="item.SubCatId==SubCatID">
                    <hr />
                    <h4 style="font-family: open sans!important;font-size: 36px;"><b><span style=" color:#14818f">All Products</span></b></h4><br />                   
                    <table id="customers" style="overflow-wrap: anywhere;">
                        <tr style="font-size:18px">
                            <th>Product Name</th>
                            <th>Active Supplement</th>
                            <th>CAS Number</th>
                            <th>Grades</th>
                            <th>Botanical Source</th>
                        </tr>
                        <tr *ngFor="let pro of item.products">
                            <td> {{pro.ProductName}}</td>
                            <td> {{pro.Name_ofthe_Active_Supplement}}</td>
                            <td>{{pro.CasNumber}}</td>
                            <td>{{pro.OfferingGrades}}</td>
                            <td>{{pro.Botanicalsource}}</td>
                        </tr>
                    </table>
                    <br />
                </div>
            </div>
        </div>
    </div>
</div>

