import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

    constructor(public router: Router) { }

  ngOnInit(): void {
  }
    onClick() {
        this.router.navigate(['/home']);
    }
}
