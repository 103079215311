
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ViewportScroller } from '@angular/common';
import { GeneralService } from '../../Services/generalservice.service';



import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


import { Router } from '@angular/router';


@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
    dataResult: any = [];
    arr: any;
    Careers: any;
    Careers1: any;
    constructor(private viewportScroller: ViewportScroller, public router: Router, public generalService: GeneralService, public http: HttpClient) { }


    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
        this.GetCareerPosts();
    }
    teamSlides: OwlOptions = {
        loop: true,
        center: true,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='fa fa-angle-left'></i>",
            "<i class='fa fa-angle-right'></i>"
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 3,
            }
        }
    }
    GetCareerPosts() {
        debugger
        this.arr = []
        this.arr.push({
            JobID: 1
        });
        var UploadFile = new FormData();
        UploadFile.append("Xml", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/Rank/CapitalTech_Careers_CRUD";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.Careers = data;
            for (var i = 0; i < this.Careers.length; i++) {
                this.Careers1 = data

            }
            //console.log(data);
        }, err => {
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    navigatingTojobdetails(data) {
        debugger
        var Jobcode = ""; var prodarr = []
        prodarr.push(data)
        var str = data.JobCode.split(" ")//spliting the category name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            Jobcode += "_" + str[i];
        }
        this.generalService.setProducts(prodarr)
        this.router.navigate(['/JobCode/' + Jobcode]);
    }
  
     
 
}
