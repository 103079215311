<div class="mo">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb crumb">
            <li class="breadcrumb-item" style="cursor:pointer;">
                <a routerLink="/"><b style="color:#00c8ee">Home</b></a>
            </li>
            <li class="breadcrumb-item bread_active" aria-current="page">

                <b class="enimy">
                    ERP Implementation
                </b>

            </li>
        </ol>
    </nav>
</div>
<div class="moooo">
    <img src="../../../assets/img/CapitaltechERPImplementationbanner.jpg" class="down"/>
    <br /><br />
    <div class="container">
        <div class="section-title">
            <h2><span>ERP Implementation</span></h2>
        </div>
        <div>
            <b>➣</b>
            <p class="con">
                Capital Tech is a pioneer in ERP System implementation (SAP, Oracle, Workday) and data
                migration. We have executed numerous complicated ERP system implementation and system migrations on a
                worldwide scale, each time on time and on budget, with no exceptions.
            </p>
        </div><br />
        <div>
            <b>➣</b>
            <p class="con">
                When it comes to Application Development / Implementation, Legacy Migration, Data migration,
                Project administration, SDLC, we play by the most noteworthy standards.
            </p>
        </div><br />
        <div>
            <b>➣</b>
            <p class="con">
                We at Capital Tech Solutions work on ERP System Implementation solutions through a
                center of excellence (CoE) to make strides in  our client’s business capabilities and competencies through a
                organized process to achieve sustainability, keeping the long term in mind.
            </p>
        </div><br />
        <div>
            <b>➣</b>
            <p class="con">
                We take after a structured process towards ERP CoE and sustainability. We start by drafting a
                formal mission and charter post which we undertake the process execution which incorporates the vital aspect,
                the operational viewpoint, and venture integration.
            </p>
        </div><br />
        <div>
            <b>➣</b>
            <p class="con">
                Next, we make the structure and align the business post roles and obligations assigned over
                the business.
                <br /><br />
            </p>
        </div>
    </div>
</div>

<style>
    p {
        color: black;
    }
    .down {
      width:100%;
      margin-top:-1%;
    }
</style>
