import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
    HomeUrl: any;
    dataResult: any = [];
    dataResult1: any;
    imgCollection: any = [];
    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }

    ngOnInit(): void {
        this.GetGallery();
    }

    GetGallery() {
        debugger
        var url = "api/Nutrigene/Get_Gallar_Images";
        this.generalService.GetData(url).then(data => {
            this.dataResult = data;
            var list = [];
            this.dataResult.filter(function (obj) {
                list.push({
                    image: "https://nutrigene.gagriglobal.com/webservices" + obj.Images,
                    thumbImage: "https://nutrigene.gagriglobal.com/webservices" + obj.Images,
                    title: 'Nutrigene', alt: 'Nutrigene'
                })
                return obj;
            })
            this.imgCollection = list;
            console.log(this.imgCollection);
        }, err => {
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }
}
