
<div class="moo1 apply" >
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb crumb">
            <li class="breadcrumb-item" style="cursor:pointer;margin-left: 89px;"><a routerLink="/"><b style="color:#00c8ee">Home</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page">
                <b class="enimy">
                    JobApplication
                </b>
            </li>
        </ol>
    </nav>
</div>
    <div class="moo" style="background-color:#ebeef1; font-size:30px;">
      <div></div>
        <h5 class="about">{{jobdetails[0].JobTitle}}</h5>

        <div class="container-fluid" style="background-color:#ebeef1;">

            <div class="container" style="background-color:white;margin-bottom:20px;">
                <div class="row" style="margin-bottom:15px">
                    <div class="col-lg-9">
                        <h5 style="margin-top:10px;margin-left:10px"><i class="fas fa-map-marker-alt"></i>{{jobdetails[0].CityName}}/{{jobdetails[0].StateName}}</h5>
                    </div>
                    <div class="col-lg-3">
                        <h5 style="margin-top:10px; margin-left: 172px;">{{jobdetails[0].JobDuration}}</h5>
                    </div>
                </div>
                <div class="row">

                    <div class="contact-form">
                        <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.value)">
                            <div class="row" style="margin-top:-23px">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <label class="sty">Name</label>
                                        <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                            <div *ngIf="name.errors.required">Name is required.</div>
                                            <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                            <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <label class="sty">Email</label>
                                        <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                        <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <label class="sty">Phone Number</label>
                                        <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                        <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <label class="sty">Experience</label>
                                        <input required ngModel name="Experience" type="text" #subject="ngModel" class="form-control" id="Experience">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <label class="sty">Comments</label>
                                        <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                                    </div>
                                </div>
                                <div class="row top_row">

                                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" style="padding-left:0;margin-left:10px">
                                        <div class="col-sm-10">
                                            <div class="images-container">
                                                <div class="m-image-wrapper">
                                                    <img [attr.src]="image" *ngIf="image" style="cursor:pointer;">
                                                </div>
                                            </div>
                                            <br />
                                            <input required ngModel name="Attachment" type="file" (change)="detectFiles(input)" #subject="ngModel"  accept="application/doc,pdf,image/*" #input class="m-img-upload-btn" style="font-size: 17px;" />

                                        </div>
                                    </div>

                                </div>


                                <div class="col-lg-12 col-md-12" style="text-align:end;">
                                    <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>



