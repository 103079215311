<div class="mo">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb crumb">
            <li class="breadcrumb-item" style="cursor:pointer;">
                <a routerLink="/"><b style="color:#00c8ee">Home</b></a>
            </li>
            <li class="breadcrumb-item bread_active" aria-current="page">

                <b class="enimy">
                    Contact Us
                </b>
                
            </li>
        </ol>
    </nav>
</div>
<div class="main-banner ">
    <div class="d-table">
        <div>
            <img src="../../../assets/img/CapitaltechContactusbanner.jpg" style="width:100%"  />

        </div>
    </div>
</div>


<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4>Get in Touch</h4>
            <h2>Let's <span>Contact</span> Us</h2>
            <p>
                <b>Looking for Job opportunities in the US?</b>
                <br />
                Capital Tech Solutions help job seekers already in US get jobs in US. We work across numerous industries, job specialties, and locations. Send your resume now to
            </p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">


                    <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Name</label>
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Name is required.</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Email</label>
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Company Name</label>
                                    <input required ngModel name="CompanyName" type="text" #subject="ngModel" class="form-control" id="CompanyName">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Phone Number</label>
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Tell Us How We Can Help</label>
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><span>Contact Number:</span> 202-971-8275</li>
                                <li>
                                    <i class="fas fa-map-marker-alt"></i> <span>Reach Us:</span>80 M St SE, Washington, District of Columbia 20003, United States

                                </li>
                                <li><i class="far fa-envelope"></i> <a href="mailto:hr@capitaltechsolutions.com"><span>Email:</span>hr@capitaltechsolutions.com</a></li>
                              <!--<li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li>-->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> <br />
        <div class="row">
            <div class="col-md-12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3106.05379376186
56!2d-77.00852438529078!3d38.87701105575182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b
7b9d69e959433%3A0xd7ff1e1a4ae8e18d!2s80%20M%20St%20SE%2C%20Washington%2C%20DC%2020003%2C%20USA!5e0!
3m2!1sen!2sin!4v1663320442947!5m2!1sen!2sin"class="map_0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                <!--<iframe src="https://www.google.com/maps/dir//80+M+St+SE,+Washington,+DC+20003,+USA/@38.876986,-77.0763757,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89b7b9d69e959433:0xd7ff1e1a4ae8e18d!2m2!1d-77.0063357!2d38.8770069"width="1295" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>-->
            </div>
        </div>



    </div>
</div>
