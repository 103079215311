
<div class="topnav navb_0" style="background-color:#0baddf;cursor:pointer;">

    <a class="active act_0" href="+1 202-971-8275" style="margin-left:8%"><i class="fa fa-fw fa-phone"></i> +1 202-971-8275</a>
    <a href="mailto:hr@capitaltechsolutions.com" target="_blank" class="ma_0">
        <i class="fa fa-envelope" aria-hidden="true"></i>  hr@capitaltechsolutions.com
    </a>
    &nbsp;
 
</div>

<div class="">
    <nav class="navbar navbar-expand-lg navbar-light bg-light card_1" [class.active]="classApplied"
         style="position:fixed;top:5px;"
         ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <div class="container-fluid" style="margin-top: 0%; margin-bottom: -0.5%;margin-left:1px;">
            <a class="navbar-brand" routerLink="/" (click)="onClick('home')">
                <img src="../../../../assets/img/Capitaltech_Logo.png" class="logo1" />
            </a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu"style="position:fixed;">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div></div>
            <div class="collapse navbar-collapse " id="navbarSupportedContent" style="font-size:14px;">
                <ul class="navbar-nav ml-auto" style="font-size: 15px;font-weight: bold; margin-top: 39px;
    margin-left: -23%;">
                    <li class="nav-item"><span class="nav-link"><a routerLink="/home">Home</a></span></li>
                    <li class="nav-item"><span class="nav-link"><a routerLink="/about-us">About&nbsp;Us</a></span></li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <!--<li class="nav-item"><span class="nav-link"><a routerLink="/Services">Services</a></span></li>-->
                    <li class="nav-item dropdown">
                        <span class="nav-link">

                            <a class="nav-item">Services</a>
                        </span><br />
                        <div class="dropdown-content" style="width: 350%; font-size: 15px; font-weight: 400; font-family: 'Poppins'">
                            <a routerLink="/DataAnalytics"><p>Data Analytics & Data Integration Architecture</p></a>
                            <a routerLink="/ITFinancial"><p>Resource Augmentation</p> </a>
                            <a routerLink="/BPO&Managed"><p>BPO & Managed Services</p></a>
                            <a routerLink="/ERPImplementation"><p>ERP Implementation</p></a>
                            <a routerLink="/GRCSolutions"><p>GRC Solutions</p></a>
                        </div>
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li class="nav-item"><span class="nav-link"><a routerLink="/reviews">Reviews</a></span></li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li class="nav-item"><span class="nav-link"><a routerLink="/Careers">Careers</a></span></li>

                    <li class="nav-item"><span class="nav-link"><a routerLink="/ContactUs">Contact&nbsp;Us</a></span></li>
                    <li></li>
                    <li></li>
                    <!--<li>
                        <p-autoComplete class="sea" placeholder="Search your Product...!" [(ngModel)]="selectedProduct" [suggestions]="searchprods" (completeMethod)="filterProducts($event)" field="ProductName" [dropdown]="false" style="padding-left:8%">
                            <ng-template let-country pTemplate="item">
                                <div class="country-item" (click)="navigatingToSearchedProduct(country)">
                                    <div>  &nbsp;&nbsp; <h6 class="fst" style=" margin-bottom: -14px;margin-left:10px;margin-top: -17px;">{{country.ProductName}}&nbsp;|&nbsp;{{country.CasNumber}}&nbsp;|&nbsp;{{country.SubCategoryName}}&nbsp;|&nbsp;{{country.CommonName}}</h6><br /></div>
                                </div>
                            </ng-template>
                        </p-autoComplete>
                    </li>-->
                </ul>
                <ul class="navbar-nav ml-auto for-responsive">
                    <!--(click)="toggleClass(); onClick('team')"<a href="javascript[0]:void;">About</a></span>-->
                    <li class="nav-item"><span class="nav-link"><a routerLink="/home">Home</a></span></li>

                    <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('about')" routerLink="/about-us">About</span></li>

                    <li class="nav-item dropdown">
                        <span class="nav-link">
                            <a class="nav-item">Services</a>
                        </span><br />
                        <div class="dropdown-content" style="width: 350%; font-size: 12px; font-weight: 400; font-family: 'Poppins'">
                            <a routerLink="/DataAnalytics"><p>Data Analytics & Data Integration Architecture</p></a>
                            <a routerLink="/ITFinancial"><p>Resource Augmentation</p> </a>
                            <a routerLink="/BPO&Managed"><p>BPO & Managed Services</p></a>
                            <a routerLink="/ERPImplementation"><p>ERP Implementation</p></a>
                            <a routerLink="/GRCSolutions"><p>GRC Solutions</p></a>
                        </div>
                    </li>

                    <li class="nav-item"><span class="nav-link"><a routerLink="reviews/">Reviews</a></span></li>
                    <li class="nav-item"><span class="nav-link"><a routerLink="/Careers">Careers</a></span></li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="toggleClass(); onClick('home')">
                            <a href="javascript[0]:void;" routerLink="/ContactUs">Contact Us</a>
                        </span>
                    </li>
                </ul>
            </div>
        </div>

    </nav>
</div>
<!--<div class="search-container">
              <form action="/search" method="get">
                  <input class="search expandright" id="searchright" type="search" name="ProductName" placeholder="Search" [(ngModel)]="selectedProduct" (completeMethod)="filterProducts($event)">
                  <label class="button searchbutton" for="searchright"><span class="mglass">&#9906;</span></label>
                  <div  *ngFor="let item of searchprods">
                      <div class="country-item" (click)="navigatingToSearchedProduct(item)" style="line-height: 34px;">
                          <div>  &nbsp;&nbsp; <b class="fst">{{item.ProductName}}&nbsp;|&nbsp;{{item.CasNumber}}&nbsp;|&nbsp;{{item.CommonName}}</b><br /></div>
                      </div>
                  </div>
              </form>
          </div>-->


<style>
    .logo1 {
        margin-left: 12%;
        width: 14%;
        margin-top: 2%;
    }
    p {
        color: black;
    }

    .topnav li {
        display: inline;
        padding: 50px;
    }

    .topnav a {
        color: white;
    }

    .topnav {
        display: block;
    }

    @media only screen and (max-width:600px) {
        .topnav {
            display: none;
        }
        .logo1{
            margin-left: 12%;
            width: 40%;
            margin-top: -1%;
        }
    }
    
    
</style>
