import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { FormGroup, FormsModule } from '@angular/forms'

import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit {
    EnquiryAry: any = [];
    formGroup: any;
    admin: any;
    TemplateId: any;
    MessageText: any;
    //ApiURL: any;
    contactForm: FormsModule;
    SubCatId: any;
    jobdetails: any;
    JobCode: any;
    JobTitle: any;
    JobDescription: any;
    CompanyName: any;
    StateName: any;
    CityName: any;
    JobTypeName: any;
    JobDuration: any;
    Experience: any;
    CreatedDate: any;
 
    whatsAppProductURL: string;
    HomeURL: any;
    CatId: any;
    catName: string;
  JobID: any;
    @Output() someEvent = new EventEmitter<string>();
    activeroute: any;
    Tests: any[];
    userDetails = { Name: "", Email: "", Number: "", Message: "" };
    Jobcode: string;
    WorklocationName: any;
    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient, public fb: FormsModule, public route: ActivatedRoute) {
        debugger
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeURL = data.Webservice;
 
            debugger
            this.JobCode = this.route.snapshot.paramMap.get('JobDetails').replace(/\_/g, " ").trim();
            this.generalService.GetProducts().subscribe(res => {
                if (res.length == 0) {//if category id is null
                    this.GetjobdeatilsbasedonJobCode()
                }
                else {
                    this.JobCode = res[0].JobCode;
                    this.GetjobdeatilsbasedonJobCode()

                }
            })
        });
    }
    ngOnInit(): void {
     
    }
    GetjobdeatilsbasedonJobCode() {
        debugger
        var UploadFile = new FormData()
        UploadFile.append("Param", this.JobCode)
        var url = "/api/Rank/Get_Capitaltech_Careers"
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.jobdetails = data;
            this.JobID = (this.jobdetails[0].JobID);
            this.JobCode = (this.jobdetails[0].JobCode);
            this.JobTitle = (this.jobdetails[0].JobTitle);
            this.JobDescription = (this.jobdetails[0].JobDescription);
            this.CompanyName = (this.jobdetails[0].CompanyName);
            this.WorklocationName = (this.jobdetails[0].WorklocationName);
            
            this.StateName = (this.jobdetails[0].StateName);
            this.CityName = (this.jobdetails[0].CityName);
            this.JobTypeName = (this.jobdetails[0].JobTypeName);
            this.JobDuration = (this.jobdetails[0].JobDuration);
            this.Experience = (this.jobdetails[0].Experience);
            this.CreatedDate = (this.jobdetails[0].CreatedDate);
            for (var i = 0; i < this.jobdetails.length; i++) {
                this.jobdetails[i].JobDescription = decodeURIComponent(this.jobdetails[i].JobDescription);
            }
         
        }, err => {
            //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
    }

    navigatingtoFarmerProduces(data) {
        var catName = "";
        var str = data.split(" ")//spliting the category name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            catName += "_" + str[i];
        }
        this.router.navigate(['/ProductDetails/' + catName]);
    }


    sendWhatsapp(item) {
        debugger
        var prodName = ""
        var str = item.JobTitle.split(" ")//spliting the category name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += "_" + str[i];
        }
        this.whatsAppProductURL = "https://capitaltech.breakingindiaapp.com/%23/Careers/" + prodName
        window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
    }
   
    ngAfterViewInit() {
        document.getElementById('preloader').classList.add('hide');
    }

    
  
   
    navigatingTojobdetails(data) {
        debugger

        this.Jobcode = "";
     
       
        var str = data.split(" ")//spliting the Jobcode name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            this.Jobcode += "_" + str[i];
        }

        this.router.navigate(['/JobId/' + data]);
    }
}
