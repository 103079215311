<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4" style="margin-top:1%">
                <h3 class="capital">Capital Tech Solutions</h3>
                <p> Capital Tech Solutions is IT Consulting and staff augmentation company headquartered in Washington DC. We are dedicated to help businesses maximize their profits by managing their information more efficiently. </p>
            </div>
            
            <div class="col-lg-3" style="padding-left: 5%;">
                <h3 class="top">Quick Links</h3>
                <p><a routerLink="/" (click)="onClick('home')">Home</a></p>
                <p><a routerLink="/about-us">About Us</a></p>
                <p><a href="https://capitaltechadmin.breakingindiaapp.com/" target="_blank">Admin Login</a></p>
                <p><a href="https://mail.ionos.com/"target="_blank">Employee Mail Login</a></p>
                <p><a routerLink="/ContactUs">Contact Us</a></p>
                <p><a routerLink="/Careers">Careers</a></p>

                <!--<a href="http://nutrigeneadmin.gagriglobal.com/"><p>Admin Login</p></a>-->

            </div>

            <div class="col-lg-4">
                <h3 class="top">Services</h3>
                <a routerLink="/DataAnalytics"><p>Data Analytics & Data Integration Architecture</p></a>
                <a routerLink="/ITFinancial"><p>Resource Augmentation</p> </a>
                <a routerLink="/BPO&Managed"><p>BPO & Managed Services</p></a>
                <a routerLink="/ERPImplementation"><p>ERP Implementation</p></a>
                <a routerLink="/GRCSolutions"><p>GRC Solutions</p></a>
            </div>
            <!--<div class="col-lg-4">
        <h3 class="top">Address</h3>
        <p>201 Teja Block,</p>
        <p>My Home Navadweepa,</p>
        <p>HITEC City, Hyderabad,</p>
        <p>Telangana, India 500081.</p>
    </div>-->
            <!--<div class="col-lg-2">
        <h3 class="top">Media</h3>
        <p><a routerLink="/NewsAndEvents">News And Events</a></p>
        <p><a routerLink="/Gallery">Gallery</a></p>
        <p><a routerLink="/Dwonloadbrochures">Downloads</a></p>
     <br/>

    </div>-->
            <div class="col-lg-1 links" style="text-align:right">
                <ul>
                    <li><a href="https://www.facebook.com/profile.php?id=100086717801762" target="_blank" class="fab fa-facebook-f"></a><br /></li>
                    <li><a href="https://twitter.com/CapitaltechS" target="_blank" class="fab fa-twitter"></a><br /></li>
                    <li><a href="https://www.linkedin.com/company/capital-tech-solutions/" target="_blank" class="fab fa-linkedin-in"></a><br /></li>
                    <!--<li><a href="#" target="_blank" class="fab fa-instagram"></a><br /></li>-->
                </ul>
            </div>
        </div>
        </div>
</footer>

<!--<app-demo-sidebar></app-demo-sidebar>-->

<ngx-scrolltop></ngx-scrolltop>
