<section id="services" class="services-area pt-100 pb-70"style="margin-top:-20%">
    <div class="container">
        <div class="section-title">
            <h2><span></span></h2>
            <p>A job description is a useful, plain-language tool that explains the tasks, duties, function and responsibilities of a position..</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <img src="../../../../assets/img/Capitalimage_6.jpg"  />
                    <!--<img src="../../../../assets/img/con_0.jpg" style="height: 160px;width: 343px;" />-->

                    <h3 style=" text-transform: uppercase;">
                        Key Steps And Importance of Businesses
                    </h3><hr />
                   
                    <p>
                        “The modern business world has organizations collecting customer data through several channels and
                        maintaining contact records across various databases. But simply having access to this data
                        is not enough as it is also essential to proactively the validity and...”
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <img src="../../../../assets/img/Capitalimage_7.jpg" />
                    <!--<img src="../../../../assets/img/cons.jpg" style="height: 160px;width: 543px;" />-->
                    <!--<div class="icon">
        <i class="fab fa-linode"></i>
    </div>
    <h3>Production</h3>-->
                    <h3 style=" text-transform: uppercase;font-size:18px;">
                        ERP Implementation
                       <br /><br />
                    </h3><hr />
                   
                    <p>
                        Capital Tech is a pioneer in ERP System implementation (SAP, Oracle, Workday) and data migration. We have executed numerous complicated ERP system implementation
                        and system migrations on a worldwide scale, each time on time and on budget, with no
                        exceptions..... <a routerLink="/ERPImplementation" style="color:blue;">Viewmore</a>
<br /><br /><br />
                    </p>
                
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <img src="../../../../assets/img/Capitalimage_8.jpg" />
                    <!--<img src="../../../../assets/img/cons_1.jpg" style="height: 160px;width: 543px;" />-->
                    <!--<div class="icon">
        <i class="fas fa-desktop"></i>
    </div>-->
                    <!--<h3>Quality </h3>-->                    <h3>
                        WHAT IS GRC, GRC SOLUTIONS, AND GRC TOOL: A BRIEF
                    </h3><hr />
                   
                         <p>
                             &nbsp;
                             &nbsp; GRC, or governance, risk, and compliance  is term used to depict the different processes and
                             frameworks companies put in put in order to ensure that they are compliant with all pertinent
                             laws and regulations...<a routerLink="/GRCSolutions" style="color:blue;">Viewmore</a>


                         </p>
                </div>
            </div>
            <!--<div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-chart-line"></i>
                    </div>
                    <h3>Analytics</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-anchor"></i>
                    </div>
                    <h3>Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-headphones"></i>
                    </div>
                    <h3>Ui & Ux Design</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-mobile-alt"></i>
                    </div>
                    <h3>Responsive Design</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-camera"></i>
                    </div>
                    <h3>Photography</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-heartbeat"></i>
                    </div>
                    <h3>Custom Support</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>-->
        </div>
    </div>
</section>
